import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export const handleLogout = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
    });
  };
};

export const saveUser = (payload) => {
  return { type: "USER_SAVE", payload };
};
